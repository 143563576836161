import axios, { AxiosInstance, AxiosResponse } from "axios";

export class FetchAdapter {
  private httpsClient: AxiosInstance;
  constructor(baseURL: string) {
    this.httpsClient = axios.create({
      baseURL,
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
      },
      withCredentials: true,
    });
  }

  async get<T>(url: string, body?: any): Promise<T> {
    const config = { data: body };
    const response: AxiosResponse<T> = await this.httpsClient.get(url, config);
    return response.data;
  }

  async post<T>(url: string, body: any): Promise<T> {
    const response: any = await this.httpsClient.post(url, body);
    return response;
  }

  async put<T>(url: string, body: any): Promise<T> {
    const response: AxiosResponse<T> = await this.httpsClient.put(url, body);
    return response.data;
  }

  async delete<T>(url: string): Promise<T> {
    const response: AxiosResponse<T> = await this.httpsClient.delete(url);
    return response.data;
  }
}
