import { useState, useContext, useEffect, useMemo } from "react";
import { API } from "../services/api/API";
import { jwtDecode } from "jwt-decode";
import React from "react";

export interface IDecodedJWT {
  id: string;
}

interface IRefreshToken {
  data: {
    token: string;
  };
}

export interface IUser {
  _id?: string;
  userType?: string;
  fullName?: string;
  email?: string;
  password?: string;
  phoneNumber?: string;
  educationalBackground?: string;
  school?: string;
  department?: string;
  graduationYear?: string;
  birthYear?: string;
  city?: string;
  district?: string;
  lastCompany?: string;
  lastJobPosition?: string;
  lastJobStartYear?: string;
  lastJobEndYear?: string;
  biography?: string;
  skills?: any[];
  foreignLanguages?: any[];
  photo?: string;
  linkedinUrl?: string;
  instagramUrl?: string;
  tiktokUrl?: string;
  otherSocialMediaUrl?: string;
  portfolioUrl?: string;
  isAdmin?: boolean;
  socialMediaLinks?: any[];
  announcements?: string[];
  jobPostings?: string[];
}

export interface IAnnouncement {
  _id?: string;
  title: string;
  content: string;
  cover_photo?: string;
  content_photo?: string;
  scope?: string;
  startDate?: Date | null;
  endDate?: Date | null;
  capacity?: string;
  location?: string;
  linkedinUrl?: string;
  registeredUsers?: string[];
  whoCreated?: string;
}

export interface IJobPosting {
  _id?: string;
  title: string;
  department: string;
  workArea: string;
  workHours: string;
  capacity: string;
  endDate?: Date | null;
  content: string;
  generalQualifications: string;
  jobDescription: string;
  registeredUsers?: string[];
  whoCreated?: string;
  isActive?: boolean;
}

interface IUserContext {
  user: IUser | null;
  isAuth: boolean;
  login: (email: string, password: string) => void;
  register: (data: IUser) => void;
  logout: () => void;
  refreshUserData: () => void;
}

export const UserContext = React.createContext<IUserContext>(
  {} as IUserContext
);

interface UserContextProviderProps {
  children: React.ReactNode;
}

const UserContextProvider: React.FC<UserContextProviderProps> = ({
  children,
}) => {
  const [user, setUser] = useState<IUser | null>(null);
  const api = useMemo(() => new API(), []);

  const isAuth = useMemo(() => !!user, [user]);

  const login = async (email: string, password: string) => {
    try {
      const response: any = await api.login(email, password);
      if (response.status === 202) {
        const token = response.data.token;
        const decoded: any = await jwtDecode(token);
        api.getPublicUser(decoded.id).then((userData: any) => {
          setUser(userData);
          window.location.href = "/my-profile";
        });
      }
    } catch (err: any) {
      return err?.response;
    }
  };

  const register = async (data: IUser) => {
    const response: any = await api.register(data);

    if (response.status === 200) {
      window.location.href = "/login";
    }
  };

  const logout = async () => {
    try {
      api.logout().then((res) => {
        setUser(null);
        window.location.href = "/";
      });
    } catch (err: any) {}
  };

  const refreshUserData = async () => {
    await handleRefreshToken();
  };

  const handleRefreshToken = async () => {
    try {
      const refreshToken = await api.refresh();
      const decoded: IDecodedJWT = jwtDecode(
        (refreshToken as IRefreshToken)?.data?.token || ""
      );
      const user: any = await api.getPublicUser(decoded.id);
      setUser(user);
    } catch (error) {
      if (!isAuth) {
        if (
          window.location.pathname !== "/login" &&
          window.location.pathname !== "/register" &&
          window.location.pathname !== "/" &&
          window.location.pathname !== "/forgot-password" &&
          window.location.pathname !== "/jobpostings" &&
          window.location.pathname !== "/announcements" &&
          !window.location.pathname.startsWith("/reset-password")
        ) {
          window.location.href = "/login";
        }
      }
    }
  };

  useEffect(() => {
    handleRefreshToken();
  }, []);

  const authContextValue: IUserContext = {
    user,
    isAuth,
    login,
    register,
    logout,
    refreshUserData,
  };

  return (
    <UserContext.Provider value={authContextValue}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;

export const useAuthContext = (): IUserContext => useContext(UserContext);
