import "../styles.css";
export const baseFontProperties = {
  color: {
    white: "#FFFFFF",
    black: "#000000",
    gray_700: "#344054",
    gray_79: "#C9C9C9",
    primary_blue_light: "#E8E8F1",
    blue_light_700: "#7979FF",
    gray_900: "#101828",
    dark_bgg: "#0D0D0D",
    dark_blue: "#020202",
    medium_light_gray: "#9C9C9C",
    light_gray: "#A5A5A5",
    bunker: "#14171F",
    blue_gray: "#4A5264",
    light_blue_gray: "#DBE1EE",
    primary_98: "#FFF8F7",
    error: "#F04438",
  },
  sizes: {
    size_10: "10px",
    size_12: "12px",
    size_14: "14px",
    size_16: "16px",
    size_18: "18px",
    size_20: "20px",
    size_24: "24px",
    size_30: "30px",
    size_32: "32px",
    size_34: "34px",
    size_36: "36px",
    size_38: "38px",
    size_48: "48px",
  },
  weight: {
    normal: 400,
    medium: 500,
    bold: 600,
    xbold: 700,
    xxbold: 800,
    xxxbold: 900,
  },
  family: {
    inter: "Inter, sans-serif",
    redland: "Redland, sans-serif",
  },
  lineHeight: {
    size_14: "14px",
    size_16: "16px",
    size_20: "20px",
    size_24: "24px",
    size_28: "28px",
    size_32: "32px",
    size_38: "38px",
    size_40: "40px",
    size_44: "44px",
    size_48: "48px",
    size_60: "60px",
  },
};

export const createTextStyle = (
  color: keyof (typeof baseFontProperties)["color"],
  size: keyof (typeof baseFontProperties)["sizes"],
  weight: keyof (typeof baseFontProperties)["weight"],
  family: keyof (typeof baseFontProperties)["family"]
) => ({
  color: baseFontProperties.color[color],
  fontSize: baseFontProperties.sizes[size],
  fontWeight: baseFontProperties.weight[weight],
  fontFamily: baseFontProperties.family[family],
});
