import axios from "axios";
import { IAnnouncement, IJobPosting, IUser } from "../../contexts/AuthContext";
import { FetchAdapter } from "./FetchAdapter";

export class API {
  private localAdapter: FetchAdapter;

  constructor() {
    this.localAdapter = new FetchAdapter(
      `${process.env.REACT_APP_TALENTUM_BE_URL}/`
    );
  }

  //// Auth

  public async login(email: string, password: string) {
    return this.localAdapter.post("auth/login", {
      email,
      password,
    });
  }

  public async register(data: IUser) {
    return this.localAdapter.post("auth/register", {
      userType: data.userType,
      fullName: data.fullName,
      email: data.email,
      password: data.password,
      phoneNumber: data.phoneNumber,
      educationalBackground: data.educationalBackground,
      school: data.school,
      department: data.department,
      graduationYear: data.graduationYear,
      birthYear: data.birthYear,
      city: data.city,
      district: data.district,
      lastCompany: data.lastCompany,
      lastJobPosition: data.lastJobPosition,
      lastJobStartYear: data.lastJobStartYear,
      lastJobEndYear: data.lastJobEndYear,
      biography: data.biography,
      skills: data.skills,
      foreignLanguages: data.foreignLanguages,
      photo: data.photo,
      linkedinUrl: data.linkedinUrl,
      instagramUrl: data.instagramUrl,
      tiktokUrl: data.tiktokUrl,
      otherSocialMediaUrl: data.otherSocialMediaUrl,
      portfolioUrl: data.portfolioUrl,
      isAdmin: data.isAdmin,
      socialMediaLinks: data.socialMediaLinks,
    });
  }

  public async refresh() {
    return this.localAdapter.post("auth/refresh", {});
  }
  public async logout() {
    return this.localAdapter.post("auth/logout", {});
  }

  //// End-Auth

  //// OTP

  // Forgot Password Send Email
  public async generateOtp(email: string): Promise<any> {
    return this.localAdapter.post(`otp/generateOtp`, {
      email,
    });
  }

  // Forgot Password Verify
  public async verifyOtp(otp: string) {
    return this.localAdapter.post(`otp/verify`, {
      otp,
    });
  }

  //// End-OTP

  //// User

  // Forgot Password Change Password
  public async changePassword(email: string, password: string): Promise<any> {
    return this.localAdapter.post(`users/changePassword/${email}`, {
      password,
    });
  }

  // Update Password
  public async updatePassword(id: string, newPassword: string): Promise<any> {
    return this.localAdapter.put(`users/passwordUpdate/${id}`, {
      newPassword,
    });
  }

  //Get User
  public async getUser(id: string) {
    return this.localAdapter.get(`users/getUser/${id}`);
  }

  //Get All User
  public async getAllUser() {
    return this.localAdapter.get(`users/getAllUser`);
  }

  //Update User
  public async updateUser(id: string, updates: { [key: string]: any }) {
    return this.localAdapter.put(`users/updateUser/${id}`, updates);
  }

  public async getPublicUser(userID: string) {
    return this.localAdapter.get(`users/getUser/${userID}`, {});
  }

  //// End-User

  //// Announcement

  // Create Announcement
  public async createAnnouncement(data: IAnnouncement) {
    return this.localAdapter.post("announcements/createAnnouncement", {
      title: data.title,
      content: data.content,
      cover_photo: data.cover_photo,
      content_photo: data.content_photo,
      scope: data.scope,
      startDate: data.startDate,
      endDate: data.endDate,
      capacity: data.capacity,
      location: data.location,
      linkedinUrl: data.linkedinUrl,
      whoCreated: data.whoCreated,
    });
  }

  // Update Announcement
  public async updateAnnouncement(id: string, updates: IAnnouncement) {
    return this.localAdapter.put(
      `announcements/updateAnnouncement/${id}`,
      updates
    );
  }

  //Delete Announcement
  public async deleteAnnouncement(id: string) {
    return this.localAdapter.post(`announcements/deleteAnnouncement/${id}`, {});
  }

  //Get User
  public async getAnnouncement(id: string) {
    return this.localAdapter.get(`announcements/getAnnouncement/${id}`);
  }

  //Get All Announcement
  public async getAllAnnouncements() {
    return this.localAdapter.get(`announcements/getAllAnnouncements`);
  }

  //Register Announcement
  public async registerAnnouncement(announcementId: string, userId: string) {
    return this.localAdapter.put(`announcements/register/${announcementId}`, {
      userId,
    });
  }

  // Unregister Announcement
  public async unregisterAnnouncement(announcementId: string, userId: string) {
    return this.localAdapter.put(`announcements/unregister/${announcementId}`, {
      userId,
    });
  }

  //// End Announcement

  //// Job Offer

  // Create Job Offer
  public async createJobOffer(data: IJobPosting) {
    return this.localAdapter.post("jobOffers/createJobOffer", {
      title: data.title,
      department: data.department,
      workArea: data.workArea,
      workHours: data.workHours,
      capacity: data.capacity,
      endDate: data.endDate,
      content: data.content,
      generalQualifications: data.generalQualifications,
      jobDescription: data.jobDescription,
      whoCreated: data.whoCreated,
    });
  }

  // Update Job Offer
  public async updateJobOffer(id: string, updates: { [key: string]: any }) {
    return this.localAdapter.put(`jobOffers/updateJobOffer/${id}`, updates);
  }

  //Delete Job Offer
  public async deleteJobOffer(id: string) {
    return this.localAdapter.post(`jobOffers/deleteJobOffer/${id}`, {});
  }

  //Get Job Offer
  public async getJobOffer(id: string) {
    return this.localAdapter.get(`jobOffers/getJobOffer/${id}`);
  }

  //Get All Job Offers
  public async getAllJobOffers() {
    return this.localAdapter.get(`jobOffers/getAllJobOffers`);
  }

  //Register Job Offer
  public async registerJobOffer(jobOfferId: string, userId: string) {
    return this.localAdapter.put(`jobOffers/register/${jobOfferId}`, {
      userId,
    });
  }

  // Unregister Job Offer
  public async unregisterJobOffer(jobOfferId: string, userId: string) {
    return this.localAdapter.put(`jobOffers/unregister/${jobOfferId}`, {
      userId,
    });
  }

  //// End Job Offer
}
