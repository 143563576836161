import { baseFontProperties } from "./_config";

export type TextStyle = React.CSSProperties;

export interface TextStyles {
  [key: string]: TextStyle;
}

export interface TextStyleContextType {
  texts: typeof defaultTextStyles;
  truncateString: (str: string, num: number) => string;
}

export const defaultTextStyles = {
  inter_medium_10_white: {
    color: baseFontProperties.color.white,
    fontSize: baseFontProperties.sizes.size_10,
    fontWeight: baseFontProperties.weight.medium,
    fontFamily: baseFontProperties.family.inter,
    lineHeight: baseFontProperties.lineHeight.size_16,
  },
  inter_normal_12_white: {
    color: baseFontProperties.color.white,
    fontSize: baseFontProperties.sizes.size_12,
    fontWeight: baseFontProperties.weight.normal,
    fontFamily: baseFontProperties.family.inter,
    lineHeight: baseFontProperties.lineHeight.size_16,
  },
  inter_medium_12_white: {
    color: baseFontProperties.color.white,
    fontSize: baseFontProperties.sizes.size_12,
    fontWeight: baseFontProperties.weight.medium,
    fontFamily: baseFontProperties.family.inter,
    lineHeight: baseFontProperties.lineHeight.size_20,
  },
  inter_medium_12_gbluelight700: {
    color: baseFontProperties.color.blue_light_700,
    fontSize: baseFontProperties.sizes.size_12,
    fontWeight: baseFontProperties.weight.medium,
    fontFamily: baseFontProperties.family.inter,
  },
  inter_normal_14_error: {
    color: baseFontProperties.color.error,
    fontSize: baseFontProperties.sizes.size_14,
    fontWeight: baseFontProperties.weight.normal,
    fontFamily: baseFontProperties.family.inter,
    lineHeight: baseFontProperties.lineHeight.size_20,
  },
  inter_normal_14_mediumlightgray: {
    color: baseFontProperties.color.medium_light_gray,
    fontSize: baseFontProperties.sizes.size_14,
    fontWeight: baseFontProperties.weight.normal,
    fontFamily: baseFontProperties.family.inter,
    lineHeight: baseFontProperties.lineHeight.size_20,
  },
  inter_normal_14_darkblue: {
    color: baseFontProperties.color.dark_blue,
    fontSize: baseFontProperties.sizes.size_14,
    fontWeight: baseFontProperties.weight.normal,
    fontFamily: baseFontProperties.family.inter,
    lineHeight: baseFontProperties.lineHeight.size_28,
  },
  inter_normal_14_bluegray: {
    color: baseFontProperties.color.blue_gray,
    fontSize: baseFontProperties.sizes.size_14,
    fontWeight: baseFontProperties.weight.normal,
    fontFamily: baseFontProperties.family.inter,
    lineHeight: baseFontProperties.lineHeight.size_20,
  },
  inter_normal_14_white: {
    color: baseFontProperties.color.white,
    fontSize: baseFontProperties.sizes.size_14,
    fontWeight: baseFontProperties.weight.normal,
    fontFamily: baseFontProperties.family.inter,
  },
  inter_normal_14_lightbluegray: {
    color: baseFontProperties.color.light_blue_gray,
    fontSize: baseFontProperties.sizes.size_14,
    fontWeight: baseFontProperties.weight.normal,
    fontFamily: baseFontProperties.family.inter,
    lineHeight: baseFontProperties.lineHeight.size_20,
  },
  inter_normal_14_primarybluelight: {
    color: baseFontProperties.color.primary_blue_light,
    fontSize: baseFontProperties.sizes.size_14,
    fontWeight: baseFontProperties.weight.normal,
    fontFamily: baseFontProperties.family.inter,
    lineHeight: baseFontProperties.lineHeight.size_20,
  },
  inter_medium_14_primarybluelight: {
    color: baseFontProperties.color.primary_blue_light,
    fontSize: baseFontProperties.sizes.size_14,
    fontWeight: baseFontProperties.weight.medium,
    fontFamily: baseFontProperties.family.inter,
    lineHeight: baseFontProperties.lineHeight.size_14,
  },
  inter_medium_14_primary98: {
    color: baseFontProperties.color.primary_98,
    fontSize: baseFontProperties.sizes.size_14,
    fontWeight: baseFontProperties.weight.medium,
    fontFamily: baseFontProperties.family.inter,
    lineHeight: baseFontProperties.lineHeight.size_20,
  },
  inter_medium_14_white: {
    color: baseFontProperties.color.white,
    fontSize: baseFontProperties.sizes.size_14,
    fontWeight: baseFontProperties.weight.medium,
    fontFamily: baseFontProperties.family.inter,
    lineHeight: baseFontProperties.lineHeight.size_20,
  },
  inter_bold_14_white: {
    color: baseFontProperties.color.white,
    fontSize: baseFontProperties.sizes.size_14,
    fontWeight: baseFontProperties.weight.bold,
    fontFamily: baseFontProperties.family.inter,
  },
  inter_normal_16_white: {
    color: baseFontProperties.color.white,
    fontSize: baseFontProperties.sizes.size_16,
    fontWeight: baseFontProperties.weight.normal,
    fontFamily: baseFontProperties.family.inter,
    lineHeight: baseFontProperties.lineHeight.size_24,
  },
  inter_normal_16_lightgray: {
    color: baseFontProperties.color.light_gray,
    fontSize: baseFontProperties.sizes.size_16,
    fontWeight: baseFontProperties.weight.normal,
    fontFamily: baseFontProperties.family.inter,
    lineHeight: baseFontProperties.lineHeight.size_24,
  },
  inter_normal_16_gray79: {
    color: baseFontProperties.color.gray_79,
    fontSize: baseFontProperties.sizes.size_16,
    fontWeight: baseFontProperties.weight.normal,
    fontFamily: baseFontProperties.family.inter,
  },
  inter_medium_16_white: {
    color: baseFontProperties.color.white,
    fontSize: baseFontProperties.sizes.size_16,
    fontWeight: baseFontProperties.weight.medium,
    fontFamily: baseFontProperties.family.inter,
    lineHeight: baseFontProperties.lineHeight.size_24,
  },
  inter_medium_16_primarybluelight: {
    color: baseFontProperties.color.primary_blue_light,
    fontSize: baseFontProperties.sizes.size_16,
    fontWeight: baseFontProperties.weight.medium,
    fontFamily: baseFontProperties.family.inter,
    lineHeight: baseFontProperties.lineHeight.size_24,
  },
  inter_bold_16_primarybluelight: {
    color: baseFontProperties.color.primary_blue_light,
    fontSize: baseFontProperties.sizes.size_16,
    fontWeight: baseFontProperties.weight.bold,
    fontFamily: baseFontProperties.family.inter,
    lineHeight: baseFontProperties.lineHeight.size_24,
  },
  inter_bold_16_gbluelight700: {
    color: baseFontProperties.color.blue_light_700,
    fontSize: baseFontProperties.sizes.size_16,
    fontWeight: baseFontProperties.weight.bold,
    fontFamily: baseFontProperties.family.inter,
  },
  inter_normal_18_gray79: {
    color: baseFontProperties.color.gray_79,
    fontSize: baseFontProperties.sizes.size_18,
    fontWeight: baseFontProperties.weight.normal,
    fontFamily: baseFontProperties.family.inter,
  },
  inter_medium_18_bunker: {
    color: baseFontProperties.color.bunker,
    fontSize: baseFontProperties.sizes.size_18,
    fontWeight: baseFontProperties.weight.medium,
    fontFamily: baseFontProperties.family.inter,
    lineHeight: baseFontProperties.lineHeight.size_24,
  },
  inter_medium_18_white: {
    color: baseFontProperties.color.white,
    fontSize: baseFontProperties.sizes.size_18,
    fontWeight: baseFontProperties.weight.medium,
    fontFamily: baseFontProperties.family.inter,
  },
  inter_medium_18_gray700: {
    color: baseFontProperties.color.gray_700,
    fontSize: baseFontProperties.sizes.size_18,
    fontWeight: baseFontProperties.weight.medium,
    fontFamily: baseFontProperties.family.inter,
  },
  inter_medium_18_gray900: {
    color: baseFontProperties.color.gray_900,
    fontSize: baseFontProperties.sizes.size_18,
    fontWeight: baseFontProperties.weight.medium,
    fontFamily: baseFontProperties.family.inter,
    lineHeight: baseFontProperties.lineHeight.size_28,
  },
  inter_medium_18_primarybluelight: {
    color: baseFontProperties.color.primary_blue_light,
    fontSize: baseFontProperties.sizes.size_18,
    fontWeight: baseFontProperties.weight.medium,
    fontFamily: baseFontProperties.family.inter,
    lineHeight: baseFontProperties.lineHeight.size_28,
  },
  inter_bold_18_white: {
    color: baseFontProperties.color.white,
    fontSize: baseFontProperties.sizes.size_18,
    fontWeight: baseFontProperties.weight.bold,
    fontFamily: baseFontProperties.family.inter,
    lineHeight: baseFontProperties.lineHeight.size_24,
  },
  inter_bold_18_bluelight700: {
    color: baseFontProperties.color.blue_light_700,
    fontSize: baseFontProperties.sizes.size_18,
    fontWeight: baseFontProperties.weight.bold,
    fontFamily: baseFontProperties.family.inter,
    lineHeight: baseFontProperties.lineHeight.size_24,
  },
  inter_normal_20_white: {
    color: baseFontProperties.color.white,
    fontSize: baseFontProperties.sizes.size_20,
    fontWeight: baseFontProperties.weight.normal,
    fontFamily: baseFontProperties.family.inter,
    lineHeight: baseFontProperties.lineHeight.size_20,
  },
  inter_normal_20_primarybluelight: {
    color: baseFontProperties.color.primary_blue_light,
    fontSize: baseFontProperties.sizes.size_20,
    fontWeight: baseFontProperties.weight.normal,
    fontFamily: baseFontProperties.family.inter,
    lineHeight: baseFontProperties.lineHeight.size_32,
  },
  inter_medium_20_darkbgg: {
    color: baseFontProperties.color.dark_bgg,
    fontSize: baseFontProperties.sizes.size_20,
    fontWeight: baseFontProperties.weight.normal,
    fontFamily: baseFontProperties.family.inter,
    lineHeight: baseFontProperties.lineHeight.size_28,
  },
  inter_xbold_20_white: {
    color: baseFontProperties.color.white,
    fontSize: baseFontProperties.sizes.size_20,
    fontWeight: baseFontProperties.weight.xbold,
    fontFamily: baseFontProperties.family.inter,
    lineHeight: baseFontProperties.lineHeight.size_32,
  },
  inter_medium_24_bluelight700: {
    color: baseFontProperties.color.blue_light_700,
    fontSize: baseFontProperties.sizes.size_24,
    fontWeight: baseFontProperties.weight.medium,
    fontFamily: baseFontProperties.family.inter,
  },
  inter_medium_24_gray900: {
    color: baseFontProperties.color.gray_900,
    fontSize: baseFontProperties.sizes.size_24,
    fontWeight: baseFontProperties.weight.medium,
    fontFamily: baseFontProperties.family.inter,
  },
  inter_bold_24_primarybluelight: {
    color: baseFontProperties.color.primary_blue_light,
    fontSize: baseFontProperties.sizes.size_24,
    fontWeight: baseFontProperties.weight.bold,
    fontFamily: baseFontProperties.family.inter,
    lineHeight: baseFontProperties.lineHeight.size_32,
  },
  inter_bold_24_white: {
    color: baseFontProperties.color.white,
    fontSize: baseFontProperties.sizes.size_24,
    fontWeight: baseFontProperties.weight.bold,
    fontFamily: baseFontProperties.family.inter,
    lineHeight: baseFontProperties.lineHeight.size_32,
  },
  inter_xbold_24_white: {
    color: baseFontProperties.color.white,
    fontSize: baseFontProperties.sizes.size_24,
    fontWeight: baseFontProperties.weight.xbold,
    fontFamily: baseFontProperties.family.inter,
    lineHeight: baseFontProperties.lineHeight.size_32,
  },
  inter_medium_30_primarybluelight: {
    color: baseFontProperties.color.primary_blue_light,
    fontSize: baseFontProperties.sizes.size_30,
    fontWeight: baseFontProperties.weight.medium,
    fontFamily: baseFontProperties.family.inter,
  },
  inter_medium_32_black: {
    color: baseFontProperties.color.black,
    fontSize: baseFontProperties.sizes.size_32,
    fontWeight: baseFontProperties.weight.medium,
    fontFamily: baseFontProperties.family.inter,
    lineHeight: baseFontProperties.lineHeight.size_38,
  },
  inter_normal_32_white: {
    color: baseFontProperties.color.white,
    fontSize: baseFontProperties.sizes.size_32,
    fontWeight: baseFontProperties.weight.normal,
    fontFamily: baseFontProperties.family.inter,
  },
  inter_medium_32_white: {
    color: baseFontProperties.color.white,
    fontSize: baseFontProperties.sizes.size_32,
    fontWeight: baseFontProperties.weight.medium,
    fontFamily: baseFontProperties.family.inter,
    lineHeight: baseFontProperties.lineHeight.size_48,
  },
  inter_medium_34_white: {
    color: baseFontProperties.color.white,
    fontSize: baseFontProperties.sizes.size_34,
    fontWeight: baseFontProperties.weight.medium,
    fontFamily: baseFontProperties.family.inter,
    lineHeight: baseFontProperties.lineHeight.size_44,
  },
  inter_medium_34_primary_blue_light: {
    color: baseFontProperties.color.primary_blue_light,
    fontSize: baseFontProperties.sizes.size_34,
    fontWeight: baseFontProperties.weight.medium,
    fontFamily: baseFontProperties.family.inter,
    lineHeight: baseFontProperties.lineHeight.size_44,
  },
  inter_xbold_34_white: {
    color: baseFontProperties.color.white,
    fontSize: baseFontProperties.sizes.size_34,
    fontWeight: baseFontProperties.weight.xbold,
    fontFamily: baseFontProperties.family.inter,
    lineHeight: baseFontProperties.lineHeight.size_40,
  },
  inter_bold_36_white: {
    color: baseFontProperties.color.white,
    fontSize: baseFontProperties.sizes.size_36,
    fontWeight: baseFontProperties.weight.bold,
    fontFamily: baseFontProperties.family.inter,
    lineHeight: baseFontProperties.lineHeight.size_44,
  },
  redland_xxxbold_38_white: {
    color: baseFontProperties.color.white,
    fontSize: baseFontProperties.sizes.size_38,
    fontWeight: baseFontProperties.weight.xxxbold,
    fontFamily: baseFontProperties.family.redland,
  },
  inter_bold_48_primarybluelight: {
    color: baseFontProperties.color.primary_blue_light,
    fontSize: baseFontProperties.sizes.size_48,
    fontWeight: baseFontProperties.weight.bold,
    fontFamily: baseFontProperties.family.inter,
    lineHeight: baseFontProperties.lineHeight.size_44,
  },
  inter_bold_48_white: {
    color: baseFontProperties.color.white,
    fontSize: baseFontProperties.sizes.size_48,
    fontWeight: baseFontProperties.weight.bold,
    fontFamily: baseFontProperties.family.inter,
    lineHeight: baseFontProperties.lineHeight.size_60,
  },
};
