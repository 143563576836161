import { TextStyleProvider } from "./contexts/TextStyleContext";
import AppRouter from "./router/AppRouter";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme/Theme";
import "./styles.css";
import UserContextProvider from "./contexts/AuthContext";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <TextStyleProvider>
        <CssBaseline />
        <UserContextProvider>
          <AppRouter />
        </UserContextProvider>
      </TextStyleProvider>
    </ThemeProvider>
  );
}

export default App;
