import React, { createContext, useContext } from "react";
import { TextStyleContextType, defaultTextStyles } from "../theme/texts";

const TextStyleContext = createContext<TextStyleContextType | undefined>(
  undefined
);

export type TextStyleProviderPropsType = {
  children: React.ReactNode;
};

const truncateString = (str: string, num: number) => {
  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
};

export const TextStyleProvider = ({ children }: TextStyleProviderPropsType) => {
  const value: TextStyleContextType = {
    texts: defaultTextStyles,
    truncateString,
  };

  return (
    <TextStyleContext.Provider value={value}>
      {children}
    </TextStyleContext.Provider>
  );
};

// Custom hook to use the text styles from the context
export const useTextStyle = () => {
  const context = useContext(TextStyleContext);
  if (!context) {
    throw new Error("useTextStyle must be used within a TextStyleProvider");
  }
  return context;
};
