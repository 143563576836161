import { createMuiTheme } from "@mui/material/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#dc004e",
    },
    background: {
      default: "#0D0D0D",
    },
  },
  typography: {
    fontFamily: "Redland, sans-serif",
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: "#2A2C2D",
          border: "1px solid #FFFFFF",
          borderRadius: "8px",
          "& .MuiOutlinedInput-root": {
            height: "48px",
            borderRadius: "8px",
            "& fieldset": {},
            "&:hover fieldset": {
              borderColor: "#FFFFFF",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#FFFFFF",
              border: "1px solid #FFFFFF",
            },
            "& .MuiInputBase-input": {
              // Changing text color for TextField
              color: "#FFFFFF",
            },
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "#E8E8F1",
            },
            "input::placeholder": {
              color: "#FFFFFF",
              opacity: 1,
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          backgroundColor: "#2A2C2D",
          height: "44px",
          borderRadius: "8px",
          border: "1px solid #FFFFFF",
          "& .MuiInputBase-input": {
            // Changing text color for Select
            color: "#FFFFFF",
          },
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#E8E8F1",
          },
          "input::placeholder": {
            color: "#FFFFFF",
            opacity: 1,
          },
        },
      },
    },
  },
});

export default theme;
