import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const MainLayout = lazy(() => import("../layout/MainLayout"));
const JobPosting = lazy(() => import("../pages/JobPosting/JobPosting"));
const Announcement = lazy(() => import("../pages/Announcement/Announcement"));

const LandingPage = lazy(() => import("../pages/LandingPage/LandingPage"));
const Login = lazy(() => import("../pages/Login/Login"));
const Register = lazy(() => import("../pages/Register/Register"));
const MyProfile = lazy(() => import("../pages/Profile/MyProfile"));
const UserProfile = lazy(() => import("../pages/Profile/UserProfile"));
const Home = lazy(() => import("../pages/Home/Home"));
const ForgotPassword = lazy(
  () => import("../pages/ForgotPassword/ForgotPassword")
);
const ResetPassword = lazy(
  () => import("../pages/ResetPassword/ResetPassword")
);

const AppRouter: React.FC = () => {
  return (
    <Router>
      <MainLayout>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/my-profile" element={<MyProfile />} />
            <Route path="/profile/:UserID" element={<UserProfile />} />
            <Route path="/jobpostings" element={<JobPosting />} />
            <Route path="/jobpostings/:jobId" element={<JobPosting />} />
            <Route path="/announcements" element={<Announcement />} />
            <Route
              path="/announcements/:announcementId"
              element={<Announcement />}
            />
            <Route path="/admin/allusers" element={<Home />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:link*" element={<ResetPassword />} />
          </Routes>
        </Suspense>
      </MainLayout>
    </Router>
  );
};

export default AppRouter;
